@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .error_notification,
  .field_with_errors {
    @apply text-error;
  }
}

.boolean.label {
  @apply justify-start;
}

.boolean .hint {
  @apply -mt-4;
  @apply pl-7;
}

.boolean .input {
  @apply mr-2;
}

.boolean .label-text {
  @apply ml-2;
}

.btn {
  @apply rounded-bl-xl;
  @apply rounded-br-none;
  @apply rounded-tl-none;
  @apply rounded-tr-xl;
}

.card {
  @apply rounded-none;
}

.checkbox {
  @apply bg-base-100;
}

.hint {
  @apply text-xs;
}

input, .input, .checkbox {
  @apply rounded-none;
}

.selected-file {
  background-color: rgb(16, 49, 60);
  border-radius: 0.2rem;
  margin-top: 5px;
  padding: 0.5rem;
  width: 30%;
}

.selected-file input[type='file'] {
  display: none;
}

/* Overwrite table rounding of edges */
.table :where(:first-child) :where(:first-child) :where(th, td):first-child {
  @apply rounded-none;
}

.table :where(:first-child) :where(:first-child) :where(th, td):last-child {
  @apply rounded-none;
}

.table :where(:last-child) :where(:first-child) :where(th, td):first-child {
  @apply rounded-none;
}

.table :where(:last-child) :where(:first-child) :where(th, td):last-child {
  @apply rounded-none;
}
/* End overwrite table rounding of edges */

/* Overwrite table colors */
.table :where(thead, tfoot) :where(th, td) {
  @apply bg-accent;
  @apply text-primary;
}

.table :where(td) {
  @apply text-primary;
}
/* End overwrite table colors */
